import React, { useState, useEffect } from 'react';
import { FaSearch, FaEnvelope, FaRegBell } from "react-icons/fa";
import blackUser from "../assets/blackUser.png";
import { Link } from 'react-router-dom';
import { projectAuth } from "../firebase";
import StripeData from '../operators/getStripeData';


// Creates a component
const Dashboardview = () => {
  const [open, setOpen] = useState(false);
  const [userEmail, setUserEmail] = useState(""); // State to store the user's email


  // useEffect to fetch the user's email when the component mounts
  useEffect(() => {
    const fetchDataAndLog = async () => {
      const productData = await StripeData(); // Call the StripeData component to get the product data
      console.log('data',productData); // Log the product data
    };

    fetchDataAndLog();


    document.title = "Dashboard - PrayitForward!";
    const user = projectAuth.currentUser;
    if (user) {
      setUserEmail(user.email);
    }
  }, []);

  /* Opens the profile popup that displays the buttons for profile, settings, and logout, if it is not open */
  const showProfile = () => {
    setOpen(!open);
  }

  const handleLogout = async () => {
    try {
      await projectAuth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  
  return (
    // UI for the header
    <div className=''>
      {/* Header container */}
      <div className='flex items-center justify-between h-[70px] shadow-lg px-[25px] '>
        {/* Search bar component */}
        <div className='flex items-center rounded-[5px]'>
          <input type="text" className=' bg-[#F8F9FC] h-[40px] outline-none pl-[13px] w-[350px] rounded-[5px] placeholder:text-[14px] leading-[20px] font-normal' placeholder='Search...' />
          <Link to="/results">
            <div className='bg-[#4E73DF] h-[40px] px-[14px] flex items-center justify-center            cursor-pointer rounded-tr-[5px] rounded-br-[5px]'>
              <FaSearch color='white' />
            </div>
          </Link>
        </div>

        {/* Notification bell and mail icons */}
        <div className='flex items-center gap-[20px]'>
          <div className='flex items-center gap-[25px] border-r-[1px] pr-[25px]'>
            <FaRegBell />
            <FaEnvelope />
          </div>

          {/* Profile section */}
          <div className='flex items-center gap-[15px] relative' onClick={showProfile} >
            {/* Users name (email) will be displayed here */}
            <p>{userEmail}</p>
            {/* Profile image */}
            <div className='h-[50px] w-[50px] rounded-full bg-[#4E73DF] cursor-pointer flex items-center justify-center relative z-40' >
              <img src={blackUser} alt="" />
            </div>

            {/* Profile options dropdown */}
            {
              open &&
              <div className='bg-white border h-[120px] w-[150px] absolute bottom-[-135px] z-20 right-0 pt-[15px] pl-[15px] space-y-[10px] flex flex-col'>
                <Link to="/account" className='cursor-pointer hover:text-[blue] font-semibold'>Profile</Link>
                <Link to="/settings" className='cursor-pointer hover:text-[blue] font-semibold'>Settings</Link>
                <span className='cursor-pointer hover:text-[blue] font-semibold' onClick={handleLogout}>Log out</span>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboardview;
