// firestoreOperations.js
import { firestore } from "../firebase";
import { collection, getDocs, setDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from "@chakra-ui/react";

export const fetchSubscriptionOptions = async () => {
    try {
      const subscriptionsCollection = collection(firestore, `/products/prod_Oc7ihFd3VSyyUJ/prices`);
      const querySnapshot = await getDocs(subscriptionsCollection);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      return [];
    }
  };
  
  export const fetchPrayerOfTheDay = async () => {
    try {
      const prayersCollection = collection(firestore, 'prayeroftheday');
      const querySnapshot = await getDocs(prayersCollection);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error('Error fetching prayer of the day:', error);
      return [];
    }
  };

  export const fetchTodayInHistory = async () => {
    try {
      const historyCollection = collection(firestore, 'todayinhistory');
      const querySnapshot = await getDocs(historyCollection);
      console.log(querySnapshot);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error('Error fetching today in history:', error);
      return [];
    }
  };

  export const fetchQuestionOfTheDay = async () => {
    try {
      const questionsCollection = collection(firestore, 'questionoftheday');
      const querySnapshot = await getDocs(questionsCollection);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error('Error fetching question of the day:', error);
      return [];
    }
  };

  export const fetchSiteSettings = async () => {
    try {
      const settingsCollection = collection(firestore, 'settings');
      const querySnapshot = await getDocs(settingsCollection);
      const getData = querySnapshot.docs.map(doc => doc.data());
      let dColor1 = getData[0].dColor1;
      let dColor2 = getData[0].dColor2;
      let lColor1 = getData[0].lColor1;
      let lColor2 = getData[0].lColor2;
      return [dColor1, dColor2, lColor1, lColor2];
    } catch (error) {
      console.error('Error fetching site settings:', error);
      return [];
    }
  };

  export const updateSiteSettings = async (dColor1, dColor2, lColor1, lColor2) => {
    try {
      const settingsCollection = collection(firestore, 'settings');
      const querySnapshot = await getDocs(settingsCollection);
      const docId = querySnapshot.docs[0].id; // Assuming there's only one document
  
      const docRef = doc(settingsCollection, docId);
      await updateDoc(docRef, {
        dColor1,
        dColor2,
        lColor1,
        lColor2,
      });
  
      console.log('Site settings updated successfully');
      return true;
    } catch (error) {
      console.error('Error updating site settings:', error);
      return false;
    }
  };

  export const addPrayerOfTheDay = async (prayerText) => {
    try {
      const prayersCollection = collection(firestore, 'prayeroftheday');
      const id = uuidv4();
      const prayerDocRef = doc(prayersCollection, id);
  
      // Create a new prayer document
      const prayerData = {
        force: false,
        ID: id,
        dateCreated: new Date().getTime(),
        lastUsed: 946684800,
        message: prayerText,
      };
  
      await setDoc(prayerDocRef, prayerData);
  
    } catch (error) {
      console.error("Error adding prayer:", error);
    }
  };

  export const addTodayInHistory = async (title, text, date ) => {
    try {
      const historyCollection = collection(firestore, 'todayinhistory');
      const id = uuidv4();
      const historyDocRef = doc(historyCollection, id);
  
      // Create a new prayer document
      const historyData = {
        ID: id,
        Active: false,
        Date: date,
        Text: text,
        Title: title,
      };
  
      await setDoc(historyDocRef, historyData);
  
    } catch (error) {
      console.error("Error adding today in history:", error);
    }
  };

  export const addQuestionOfTheDay = async (questionText) => {
    try {
      const questionsCollection = collection(firestore, 'questionoftheday');
      const id = uuidv4();
      const questionDocRef = doc(questionsCollection, id);
  
      // Create a new prayer document
      const questionData = {
        force: false,
        ID: id,
        dateCreated: new Date().getTime(),
        lastUsed: 946684800,
        message: questionText,
        yesV: 0,
        noV: 0,
      };
  
      await setDoc(questionDocRef, questionData);
  
    } catch (error) {
      console.error("Error adding question:", error);
    }
  };

  export const queuePrayerOfTheDay = async (prayerID) => {
    try {
      const prayersCollection = collection(firestore, 'prayeroftheday');
    const prayerDocRef = doc(prayersCollection, prayerID);

    // Get the current value of the "force" field
    const prayerDoc = await getDoc(prayerDocRef);
    if (prayerDoc.exists()) {
      const currentForceValue = prayerDoc.data().force;

      // Toggle the "force" field
      await updateDoc(prayerDocRef, {
        force: !currentForceValue,
      });

      console.log(`"force" field has been toggled to ${!currentForceValue}`);
    } else {
      console.log('Document does not exist.');
    }
    } catch (error) {
      console.error("Error queuing prayer:", error);
    }
  };

  export const queueQuestionOfTheDay = async (questionID) => {
    try {
    const questionsCollection = collection(firestore, 'questionoftheday');
    const questionDocRef = doc(questionsCollection, questionID);

    // Get the current value of the "force" field
    const questionDoc = await getDoc(questionDocRef);
    if (questionDoc.exists()) {
      const currentForceValue = questionDoc.data().force;

      // Toggle the "force" field
      await updateDoc(questionDocRef, {
        force: !currentForceValue,
      });

      console.log(`"force" field has been toggled to ${!currentForceValue}`);
    } else {
      console.log('Document does not exist.');
    }
    } catch (error) {
      console.error("Error queuing question:", error);
    }
  };

  export const deletePrayerOfTheDay = async (prayerID) => {
    try {
      const prayersCollection = collection(firestore, 'prayeroftheday');
      const prayerDocRef = doc(prayersCollection, prayerID);
  
      // Update the existing prayer document to set "force" to true
      await deleteDoc(prayerDocRef);

    } catch (error) {
      console.error("Error deleting prayer:", error);
    }
  };

  export const deleteTodayInHistory = async (historyID) => {
    try {
      const historyCollection = collection(firestore, 'todayinhistory');
      const historyDocRef = doc(historyCollection, historyID);
  
      // Update the existing prayer document to set "force" to true
      await deleteDoc(historyDocRef);

    } catch (error) {
      console.error("Error deleting today in history:", error);
    }
  };

  export const deleteQuestionOfTheDay = async (questionID) => {
    try {
      const questionsCollection = collection(firestore, 'questionoftheday');
      const questionDocRef = doc(questionsCollection, questionID);
  
      // Update the existing question document to set "force" to true
      await deleteDoc(questionDocRef);

    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };


