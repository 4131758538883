import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Dashboardview from '../components/Dashboardview';
import { useTable } from 'react-table';
import { FaTimes, FaClock } from 'react-icons/fa'; // Import the icons
import './your-styles.css'; // Import your CSS file
import { addPrayerOfTheDay, addTodayInHistory, deletePrayerOfTheDay, deleteTodayInHistory, queuePrayerOfTheDay } from '../operators/manageSiteContent';
import { fetchTodayInHistory } from './../operators/manageSiteContent';


export default function Calendar() {
  const [dayInHistory, setDayInHistory] = useState([]);
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    document.title = 'Today in History - PrayitForward!';

    const fetchHistory = async () => {
      setDayInHistory(await fetchTodayInHistory());
      console.log(dayInHistory);
    };
  
    fetchHistory(); // Call the renamed function here
  }, []);

  const data = React.useMemo(() => dayInHistory, [dayInHistory]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
      },
      {
        Header: 'Title',
        accessor: 'Title',
      },
      {
        Header: 'Text',
        accessor: 'Text',
      },
      {
        Header: 'Date',
        accessor: 'Date',
        Cell: ({ value }) => {
          const date = new Date(value);
          if (date.toLocaleString() === 'Invalid Date') {
            return 'Never';
          } else {
            return date.toLocaleString(); // Convert to a readable date string
          }
        },
      },
      {
        Header: 'Active',
        accessor: 'Active',
        Cell: ({ value }) => {
          if (!value) {
            return 'False';
          } else {
            return 'True';
          }
        },
      },
      {
        Header: 'Delete',
        accessor: 'delete',
        Cell: ({ row }) => {
          return (
            <td
              style={{
                padding: '8px',
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
              }}
              onClick={ async() => {
                deleteTodayInHistory(row.original.ID);
                const updatedHistory = await fetchTodayInHistory();
                setDayInHistory(updatedHistory);
              }}
            >
              <FaTimes />
            </td>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const handleButtonSubmit = async () => {
    const selectedDate = new Date(date);
  
  // Adjust the date to UTC to handle timezone differences
  selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset());
  
  // Pass the adjusted date to addTodayInHistory
  await addTodayInHistory(title, text, selectedDate.toISOString());
    const updatedHistory = await fetchTodayInHistory();
    setDayInHistory(updatedHistory);  
    setDate(''); 
    setText('');
    setTitle('');
  };

  return (
    <>
      <div className="">
        <div className="flex overflow-scroll">
          <div className="basis-[12%] h-[100vh]">
            <Sidebar />
          </div>
          <div className="basis-[88%] border overflow-scroll h-[100vh]">
            <Dashboardview />

            <div className="flex items-stretchrounded-md overflow-hidden border border-[#4E73DF]">
              <input
                type="text"
                placeholder="Enter your title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#4E73DF', padding: '5px', color: '#4E73DF'  }}
              />
              <input
                type="text"
                placeholder="Enter your text..."
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#4E73DF', padding: '5px', color: '#4E73DF'  }}
              />
              <input
                type="date"
                placeholder="Enter your date..."
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#4E73DF', minWidth: '200px', padding: '5px', color: '#4E73DF' }}
              />
              
              

              <button className="custom-button" onClick={handleButtonSubmit}>
                Add Prayer
              </button>
            </div>

            <table
              {...getTableProps()}
              style={{
                width: '100%',
                border: '1px solid #ddd',
                borderCollapse: 'collapse',
              }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          padding: '8px',
                          textAlign: 'left',
                          backgroundColor: '#f2f2f2',
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid #ddd',
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
