import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'; // Import signInWithEmailAndPassword

const firebaseConfig = {
  apiKey: "AIzaSyBeE75NRo-EGNP9YIxMhzsEVSIQGv1cy1c",
  authDomain: "admin-prayitforward.firebaseapp.com",
  projectId: "admin-prayitforward",
  storageBucket: "admin-prayitforward.appspot.com",
  messagingSenderId: "1097852331343",
  appId: "1:1097852331343:web:113e42924fa782f604d13a",
  measurementId: "G-KWMFXD1D5T"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app); 

let error = null;

const login = async (email, password) => {
  error = null;
  try {
    // Use signInWithEmailAndPassword from 'auth'
    const res = await signInWithEmailAndPassword(auth, email, password);
    error = null;
    return res;
  } catch (e) {
    error = e.message;
    console.log(error);
  }
};

const userLogin = () => {
  return { error, login };
};

export default userLogin;
