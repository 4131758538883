import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import Sidebar from '../components/Sidebar';
import Dashboardview from '../components/Dashboardview';
import { fetchSubscriptionOptions } from '../operators/manageSiteContent';


export default function SubscriptionPage() {
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  useEffect(() => {
    document.title = 'Subscription Data - PrayitForward';

    const fetchSubscriptionData = async () => {
      setSubscriptionOptions(await fetchSubscriptionOptions());
    }

    fetchSubscriptionData();
  }, []);

  return (
    
    <div className="subscription-page" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '12%' }}>
        <Sidebar />
      </div>
      <div className="basis-[88%] border overflow-scroll h-[100vh]">

          <Dashboardview />
          <Flex mb={'50px'}></Flex>
        <div className="subscription-boxes" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          {subscriptionOptions.map((option, index) => (
            <div key={index} style={{ padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', width: '100%', backgroundColor: '#4E73DF', cursor: 'pointer' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '10px', color: 'white' }}>Subscription {index + 1}</h2>
                <p style={{ fontSize: '1rem', marginBottom: '5px', color: 'white' }}>Monthly Cost: ${option.unit_amount / 100}</p>
              </div>
              <button style={{ background: 'transparent', border: 'none', cursor: 'pointer', color: '#4E73DF' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  <circle cx="12" cy="12" r="3" />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
    
  );
}
