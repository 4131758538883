import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from '../App';
import Main from '../components/Main';
import Calendar from '../pages/Calendar';
import GeneralSettings from '../pages/GeneralSettings';
import ManageUsers from '../pages/ManageUsers';
import DailyPrayers from '../pages/DailyPrayers';
import DailyQuestions from '../pages/DailyQuestions';
import PostAnalytics from '../pages/PostAnalytics';
import Authentication from '../pages/Authentication';
import PrivateRoutesLayout from '../layouts/PrivateRoutesLayout';
import ManageGroups from '../pages/ManageGroups';
import SubscriptionPage from '../pages/SubscriptionData';





const Router = () => {
    return (
        <div>
            {/* Set up the BrowserRouter */}
            <BrowserRouter>
                {/* Define the routes */}
                <Routes>
                    {/* Route for login page */}
                    <Route path="/login">
                        {/* Nested route for the index */}
                        <Route index element={<Authentication />} />
                    </Route>


                    {/* Route for dash page */}
                    <Route path="/" element={<App />}>
                        {/* Nested route for the index */}
                        <Route index element={<Main />} />
                    </Route>
        
                    <Route path="/dayinhistory" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the "index" */}
                        <Route index element={<Calendar />} />
                    </Route>

                    {/* Route for general settings 1 */}
                    <Route path="/general-settings" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<GeneralSettings />} />
                    </Route>

                    {/* Route for manage users */}
                    <Route path="/manage-users" element={<PrivateRoutesLayout/>}>
                        {/* Nested route for the index */}
                        <Route index element={<ManageUsers />} />
                    </Route>

                    {/* Route for manage groups */}
                    <Route path="/manage-groups" element={<PrivateRoutesLayout/>}>
                        {/* Nested route for the index */}
                        <Route index element={<ManageGroups />} />
                    </Route>

                    {/* Route for manage subscriptions */}
                    <Route path="/subscriptions" element={<PrivateRoutesLayout/>}>
                        {/* Nested route for the index */}
                        <Route index element={<SubscriptionPage />} />
                    </Route>

                    {/* Route for post analytics */}
                    <Route path="/post-analytics" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<PostAnalytics />} />
                    </Route>

                    {/* Route for daily prayers */}
                    <Route path="/daily-prayers" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<DailyPrayers />} />
                    </Route>

                    {/* Route for daily questions */}
                    <Route path="/daily-questions" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<DailyQuestions />} />
                    </Route>

                    

                    {/* Route for generating reports */}
                    <Route path="/generate-report" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<Main />} />
                    </Route>

                    {/* Route for admin account */}
                    <Route path="/account" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<Main />} />
                    </Route>


                    {/* Route for settings page */}
                    <Route path="/settings" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<Main />} />
                    </Route>

                     {/* Route for search results page */}
                     <Route path="/results" element={<PrivateRoutesLayout />}>
                        {/* Nested route for the index */}
                        <Route index element={<Main />} />
                    </Route>


                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default Router;
