/*

This file contains all of the components for the post analytics page

*/

import React, { useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Dashboardview from '../components/Dashboardview';

export default function PostAnalytics() {
  useEffect(() => {
    // Set the title when the component mounts
    document.title = "Analytics - PrayitForward";
  }, []);
  return (
    <div className="">
      {/* The main layout is divided into two columns */}
      <div className="flex overflow-scroll ">
        {/* Renders the Dashboardview file */}
        {/* Sets the sidebar space to 12 percent of the screen */}
        <div className="basis-[12%] h-[100vh]">
          <Sidebar />
        </div>
        {/* Main Content Area */}
        {/* Sets the space to 12 percent of the screen */}
        <div className="basis-[88%] border overflow-scroll h-[100vh]">
          {/* Renders the Dashboardview file */}
          <Dashboardview />
          {/* The Outlet is a placeholder for rendering nested routes */}
        </div>
      </div>
    </div>
  );
}
