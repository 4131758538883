import React from 'react'
import { FaRegCalendarMinus, FaEllipsisV } from "react-icons/fa"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, } from 'recharts';
import PieComponent from './PieComponent';
import { Progress } from 'antd';
import error from "../assets/error.png"
import { Link } from 'react-router-dom';


/* 

This file contains all of the components on the main page of the dashboard
CONTAINED
    4 Main metric hover components
    Member growth chart
    Membership length pie chart
    Projects overview
    Resources
    Generate report button

TO DO
    - Add functionality to click the button to generate a report
    - Add Data based backing to the charts and graphs
    - Add additional resources for the admins
    - Add functionality to switch the data source using the 3 dots

*/


// The hardcoded data for the line chart
const datas = [
    {
        name: 'January',
        'New Members': 4000,
        "Dropped Members": 2000,
    },
    {
        name: 'February',
        'New Members': 3000,
        "Dropped Members": 1000,
    },
    {
        name: 'March',
        'New Members': 2000,
        "Dropped Members": 1500,
    },
    {
        name: 'April',
        'New Members': 2780,
        "Dropped Members": 1800,
    },
    {
        name: 'May',
        'New Members': 1890,
        "Dropped Members": 1900,
    },
    {
        name: 'June',
        'New Members': 2390,
        "Dropped Members": 2100,
  
    },
    {
        name: 'July',
        'New Members': 3490,
        "Dropped Members": 2500,
    },
];



// Creates a component
const Main = () => {



    return (
        // The header section
        <div className='px-[25px] pt-[25px] bg-[#F8F9FC] pb-[40px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-[28px] leading-[34px] font-normal text-[#5a5c69] cursor-pointer'>PrayItForward Dashboard</h1>
                
                {/* Generate report button, needs to link to a new page to generate a report */}
                <Link to="/generate-report">
                    <button className='bg-[#2E59D9] h-[32px] rounded-[3px] text-white flex items-center justify-center  px-[8px]'>
                        Generate Report
                    </button>
                </Link>
            </div>
            {/* Creates the 4 hover elements containing the main metrics */}
            <div className='grid grid-cols-4 gap-[30px] mt-[25px] pb-[15px]'>
                {/* 1st of 4 main metrics - EARNINGS (MONTHLY) */}
                {/* Sets the color and the border for the hover element */}
                <div className=' h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#4E73DF] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
                    <div>
                        {/* Hard coded in values */}
                        <h2 className='text-[#B589DF] text-[11px] leading-[17px] font-bold'>EARNINGS (MONTHLY)</h2>
                        <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>$40,000</h1>
                    </div>
                    {/*Sets the color of the calendar icon*/}
                    <FaRegCalendarMinus fontSize={28} color="" />

                </div>
                {/* 2nd of 4 main metrics - EARNINGS (ANNUAL) */}
                {/* Sets the color and the border for the hover element */}
                <div className=' h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#1CC88A] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
                    <div>
                        {/* Hard coded in values */}
                        <h2 className='text-[#1cc88a] text-[11px] leading-[17px] font-bold'>EARNINGS (ANNUAL)</h2>
                        <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>$240,000</h1>
                    </div>
                    {/*Sets the color of the calendar icon*/}
                    <FaRegCalendarMinus fontSize={28} color="" />
                </div>
                {/* 3rd of 4 main metrics - New Members (MONTHLY) */}
                {/* Sets the color and the border for the hover element */}
                <div className=' h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#36B9CC] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
                    <div>
                        {/* Hard coded in values */}
                        <h2 className='text-[#1cc88a] text-[11px] leading-[17px] font-bold'>NEW MEMBERS (MONTHLY)</h2>
                        <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>100</h1>
                    </div>
                    {/*Sets the color of the calendar icon*/}
                    <FaRegCalendarMinus fontSize={28} />
                </div>
                {/* 4th of 4 main metrics - New Members (DAILY) */}
                {/* Sets the color and the border for the hover element */}
                <div className=' h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#F6C23E] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
                    <div>
                        {/* Hard coded in values */}
                        <h2 className='text-[#1cc88a] text-[11px] leading-[17px] font-bold'>NEW MEMBERS (DAILY)</h2>
                        <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>10</h1>
                    </div>
                    {/*Sets the color of the calendar icon*/}
                    <FaRegCalendarMinus fontSize={28} />
                </div>

            </div>
            {/* Creates the member growth section */}
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[70%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Key Metrics</h2>
                        {/* Add the functionality to switch the data source */}
                        <FaEllipsisV color="gray" className='cursor-pointer' />
                    </div>

                    {/* Creates the line chart */}
                    <div className="w-full">
                        {/* <canvas id="myAreaChart"></canvas> */}
                        {/* <Line options={options} data={data} /> */}
                        <LineChart
                            width={1150}
                            height={500}
                            data={datas}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="New Members" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="Dropped Members" stroke="#4e73df" />
                        </LineChart>
                    </div>

                </div>
                {/* Creates the pie chart for member length */}
                <div className='basis-[30%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Membership Length</h2>
                    </div>
                    <div className='pl-[35px]'>

                        <PieComponent />

                        {

                        }
                    </div>
                </div>
            </div>
            {/* Creates the goals section */}
            <div className='flex mt-[22px] w-full gap-[30px]'>
                <div className='basis-[55%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'>Projects Overview</h2>
                        
                    </div>
                    {/* Hard coded in values */}
                    <div className='px-[25px] space-y-[15px] py-[15px]'>
                        <div>
                            <h2>Total Monthly Posts</h2>
                            <Progress percent={30} strokeColor="#E74A3B" />
                        </div>
                        <div>
                            <h2>New Members</h2>
                            <Progress percent={50} status="active" strokeColor="#F6C23E" />
                        </div>
                        <div>
                            <h2>Total Engagement</h2>
                            <Progress percent={70} status="active" strokeColor="#4E73DF" />
                        </div>
                        <div>
                            <h2>Total Revenue</h2>
                            <Progress percent={100} strokeColor="#36B9CC" />
                        </div>
                    </div>





                </div>
                {/* Creates the resources section */}
                <div className='basis-[45%] border bg-white shadow-md cursor-pointer rounded-[4px]'>
                    <div className='bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED]'>
                        <h2 className='text-[#4e73df] text-[16px] leading-[19px] font-bold'> Resources</h2>
                    </div>
                    <div className='pl-[35px] flex items-center justify-center h-[100%]'>
                        <div>
                            <img src={error} alt="" className='transform scale-[135%]' />
                            <p className='mt-[15px] text-semibold text-gray-500'>No data available</p>
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default Main   