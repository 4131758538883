import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Dashboardview from '../components/Dashboardview';
import { useTable } from 'react-table';
import { FaTimes, FaClock } from 'react-icons/fa'; // Import the icons
import './your-styles.css'; // Import your CSS file
import { addPrayerOfTheDay, deletePrayerOfTheDay, fetchPrayerOfTheDay, queuePrayerOfTheDay } from '../operators/manageSiteContent';

export default function DailyPrayers() {
  const [dailyPrayers, setDailyPrayers] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.title = 'Daily Prayers - PrayitForward';

    const fetchDailyPrayers = async () => {
      setDailyPrayers(await fetchPrayerOfTheDay());
    };

    fetchDailyPrayers();
  }, []);

  const data = React.useMemo(() => dailyPrayers, [dailyPrayers]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: ({ value }) => {
          const date = new Date(value);
          if (date.toLocaleString() === 'Invalid Date') {
            return 'Never';
          } else {
            return date.toLocaleString(); // Convert to a readable date string
          }
        },
      },
      {
        Header: 'Last Used',
        accessor: 'lastUsed',
        Cell: ({ value }) => {
          const date = new Date(value);
          if (date.toLocaleString() === 'Invalid Date') {
            return 'Never';
          } else {
            return date.toLocaleString(); // Convert to a readable date string
          }
        },
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ value }) => {
          if (!value) {
            return 'False';
          } else {
            return 'True';
          }
        },
      },
      {
        Header: 'Queued',
        accessor: 'force',
        Cell: ({ value }) => {
          if (!value) {
            return 'False';
          } else {
            return 'True';
          }
        },
      },
      {
        Header: 'Delete',
        accessor: 'delete',
        Cell: ({ row }) => {
          return (
            <td
              style={{
                padding: '8px',
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
              }}
              onClick={ async() => {
                deletePrayerOfTheDay(row.original.ID);
                const updatedPrayers = await fetchPrayerOfTheDay();
                setDailyPrayers(updatedPrayers);
              }}
            >
              <FaTimes />
            </td>
          );
        },
      },
      {
        Header: 'Queue',
        accessor: 'queue',
        Cell: ({ row }) => {
          return (
            <td
              style={{
                padding: '8px',
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
              }}
              onClick={ async () => {
                queuePrayerOfTheDay(row.original.ID);
                const updatedPrayers = await fetchPrayerOfTheDay();
                setDailyPrayers(updatedPrayers);
              }}
            >
              <FaClock />
            </td>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const handleButtonSubmit = async () => {
    await addPrayerOfTheDay(message);
    // After adding a new prayer, update the list of daily prayers
    const updatedPrayers = await fetchPrayerOfTheDay();
    setDailyPrayers(updatedPrayers);
    setMessage(''); // Clear the input field
  };

  return (
    <>
      <div className="">
        <div className="flex overflow-scroll">
          <div className="basis-[12%] h-[100vh]">
            <Sidebar />
          </div>
          <div className="basis-[88%] border overflow-scroll h-[100vh]">
            <Dashboardview />

            <div className="flex items-stretchrounded-md overflow-hidden border border-[#4E73DF]">
              <input
                type="text"
                placeholder="Enter your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button className="custom-button" onClick={handleButtonSubmit}>
                Add Prayer
              </button>
            </div>

            <table
              {...getTableProps()}
              style={{
                width: '100%',
                border: '1px solid #ddd',
                borderCollapse: 'collapse',
              }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          padding: '8px',
                          textAlign: 'left',
                          backgroundColor: '#f2f2f2',
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: '8px',
                              borderBottom: '1px solid #ddd',
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
