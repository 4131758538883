import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Dashboardview from '../components/Dashboardview';
import {
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ChromePicker } from 'react-color';
import './GeneralSettings.css';
import { collection } from 'firebase/firestore';
import { firestore } from '../firebase';
import { fetchSiteSettings, updateSiteSettings } from '../operators/manageSiteContent';

export default function GeneralSettings() {
  const [logoUrl, setLogoUrl] = useState('');
  const [lightColorPalette, setLightColorPalette] = useState(['#FFFFFF', '#000000']);
  const [darkColorPalette, setDarkColorPalette] = useState(['#FFFFFF', '#000000']);
  const [currentColorIndex, setCurrentColorIndex] = useState(null);
  const [currentPaletteType, setCurrentPaletteType] = useState('');
  const [temporaryLightPalette, setTemporaryLightPalette] = useState(['#FFFFFF', '#000000']);
  const [temporaryDarkPalette, setTemporaryDarkPalette] = useState(['#FFFFFF', '#000000']);
  const [pickerOpen, setPickerOpen] = useState(false);
  const siteSettings = fetchSiteSettings();



 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedColors = await fetchSiteSettings();
        setLightColorPalette([fetchedColors[2], fetchedColors[3]]);
        setDarkColorPalette([fetchedColors[0], fetchedColors[1]]);

        // Set temporary palettes to initial colors
        setTemporaryLightPalette([fetchedColors[2], fetchedColors[3]]);
        setTemporaryDarkPalette([fetchedColors[0], fetchedColors[1]]);
      } catch (error) {
        console.error('Error fetching site settings:', error);
      }
    };

    fetchData();
    document.title = 'Settings - PrayitForward!';
    setLogoUrl('https://via.placeholder.com/50');

    // Set initial colors
    setLightColorPalette(['#4267B2', 'white']);
    setDarkColorPalette(['#4267B2', 'black']);

    // Set temporary palettes to initial colors
    setTemporaryLightPalette(['#4267B2', 'white']);
    setTemporaryDarkPalette(['#4267B2', 'black']);

    if (siteSettings) {
      console.log(siteSettings);
    }
  }, []);

  const handleColorChange = (color) => {
    if (currentPaletteType === 'light') {
      const newPalette = [...temporaryLightPalette];
      newPalette[currentColorIndex] = color.hex;
      setTemporaryLightPalette(newPalette);
    } else if (currentPaletteType === 'dark') {
      const newPalette = [...temporaryDarkPalette];
      newPalette[currentColorIndex] = color.hex;
      setTemporaryDarkPalette(newPalette);
    }
  };

  const handleSaveChanges = async () => {
    // Assuming temporaryLightPalette and temporaryDarkPalette are updated before this function is called
    setLightColorPalette(temporaryLightPalette);
    setDarkColorPalette(temporaryDarkPalette);
    setCurrentColorIndex(null);
    setCurrentPaletteType('');
    setPickerOpen(false);

    // Update site settings in Firestore with the new color palettes
    try {
      const updateResult = await updateSiteSettings(
        temporaryDarkPalette[0], 
        temporaryDarkPalette[1], 
        temporaryLightPalette[0], 
        temporaryLightPalette[1] 
      );

    } catch (error) {
      console.error('Error updating site settings:', error);
    }
  };



  const handleDiscardChanges = () => {
    console.log('Discarding changes...');
    setCurrentColorIndex(null);
    setCurrentPaletteType('');
    setPickerOpen(false);
  };

  const handleLogoChange = (event) => {
    const newLogoUrl = URL.createObjectURL(event.target.files[0]);
    setLogoUrl(newLogoUrl);
  };

  useEffect(() => {
    console.log('Upload changes')
  }, [lightColorPalette, darkColorPalette]);

  return (
    <div className="">
      <div className="flex overflow-scroll ">
        <div className="basis-[12%] h-[100vh]">
          <Sidebar />
        </div>
        <div className="basis-[88%] border overflow-scroll h-[100vh]">
          <div>
            <Dashboardview />
            <Text fontSize="100px" color={lightColorPalette[0]} fontWeight="bold" mt={20} ml={320}>
              PrayItForward!
            </Text>
          </div>

          <Flex justifyContent="space-between" alignItems="center" mt={40} ml={140} mr={140}>
            <Box
              fontSize="80px"
              fontWeight="bold"
              bg={lightColorPalette[1]}
              p={4}
              borderRadius="md"
              display="inline-block"
            >
              <Text color={lightColorPalette[0]}>Light Mode</Text>
            </Box>

            <Box
              fontSize="80px"
              fontWeight="bold"
              bg={darkColorPalette[1]}
              p={4}
              ml={-40}
              borderRadius="md"
              display="inline-block"
            >
              <Text color={darkColorPalette[0]}>Dark Mode</Text>
            </Box>
          </Flex>

          <Flex justifyContent="space-between" alignItems="center" mt={40} ml={140} mr={140}>
            <Text color={lightColorPalette[0]} fontWeight="bold" fontSize={80} ml={-30}>
              Color Palette
            </Text>
            <Text color={darkColorPalette[0]} fontWeight="bold" fontSize={80} mr={-40}>
              Color Palette
            </Text>
          </Flex>

          <Flex mt={5} ml={145}>
            {lightColorPalette.map((color, index) => (
              <Box
                key={index}
                bg={color}
                w="180px"
                h="180px"
                borderRadius="50%"
                borderColor={'black'}
                borderWidth={4}
                borderStyle={'solid'}
                mr={40}
                cursor="pointer"
                onClick={() => {
                  setCurrentColorIndex(index);
                  setCurrentPaletteType('light');
                  setPickerOpen(true);
                }}
              >
                {index === currentColorIndex && currentPaletteType === 'light' && pickerOpen && (
                  <div>
                    <Button className="Button Button--Save" onClick={handleSaveChanges} mt={2}>
                      Save Changes
                    </Button>
                    <ChromePicker color={temporaryLightPalette[index]} onChange={handleColorChange} />
                  </div>
                )}
              </Box>
            ))}
          </Flex>

          <Flex mt={-175} ml={795}>
            {darkColorPalette.map((color, index) => (
              <Box
                key={index}
                bg={color}
                w="180px"
                h="180px"
                borderRadius="50%"
                borderColor={'black'}
                borderWidth={4}
                borderStyle={'solid'}
                mr={40}
                cursor="pointer"
                onClick={() => {
                  setCurrentColorIndex(index);
                  setCurrentPaletteType('dark');
                  setPickerOpen(true);
                }}
              >
                {index === currentColorIndex && currentPaletteType === 'dark' && pickerOpen && (
                  <div>
                    <Button className="Button Button--Save" onClick={handleSaveChanges} mt={2}>
                      Save Changes
                    </Button>
                    <ChromePicker color={temporaryDarkPalette[index]} onChange={handleColorChange} />
                  </div>
                )}
              </Box>
            ))}
          </Flex>
        </div>
      </div>
    </div>
  );
}
