import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Dashboardview from '../components/Dashboardview';
import { fetchUsersFromFirestore, fetchSubscriptionsForUser, fetchGroupsFromFirestore } from '../operators/manageUserOperations'; // Import Firestore functions



export default function ManageGroups() {
  const [searchQuery, setSearchQuery] = useState('');
  const [showLimit, setShowLimit] = useState(6);
  const [groupData, setGroupData] = useState([]); // State to hold user data


  useEffect(() => {
    document.title = "Groups - PrayitForward";

    // Fetch user data and statuses from Firestore when the component mounts
    const fetchGroupData = async () => {
      const groups = await fetchGroupsFromFirestore();
      setGroupData(groups);

    };

    fetchGroupData();
  }, []);

  // Function to filter users based on the search query
  const filterGroups = () => {
    return groupData.filter(group => {
      const id = group.id || '';
      const name = group.name || '';
      const members = '0' || '';

      return (
        id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        name.toLowerCase().includes(searchQuery.toLowerCase()) 
      );
    });
  };

  // Display a limited number of users initially
  const displayedGroups = searchQuery ? filterGroups() : groupData.slice(0, showLimit);

  // Function to open a link in a new tab with the user's ID
  const openAccessLink = (groupId) => {
    if (groupId) {
      const accessLink = `https://prayitforward.com/protected/groups/${groupId}`;
      window.open(accessLink, '_blank');
    }
  };


  return (
    <div className="">
      {/* The main layout is divided into two columns */}
      <div className="flex overflow-scroll">
        {/* Sidebar */}
        <div className="basis-[12%] h-[100vh]">
          <Sidebar />
        </div>
        {/* Main Content Area */}
        <div className="basis-[88%] border overflow-scroll h-[100vh]">
          <Dashboardview />
          <div className="flex gap-4 mb-4 rounded-md overflow-hidden border border-[#4E73DF]">
            <input
              type="text"
              placeholder="Search groups..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className="w-full h-10 px-4 bg-white focus:outline-none"
            />
          </div>
          {/* Adding user info boxes */}
          <div className="flex flex-col gap-[15px]">
            {displayedGroups.map((group, index) => (
              <div
                key={index}
                className="w-full h-[100px] rounded-[8px] bg-white border-l-[0px] border-[#4E73DF] flex items-center justify-between px-[30px] shadow-lg transform hover:scale-[101%] transition duration-300 ease-out user-info-box"
              >
                <div className="flex items-center">
                  <div className="flex-grow">
                    <p className="font-bold text-lg">{group.name}</p>
                  </div>
                  <div className="border-l h-12 mx-4"></div> {/* Thin vertical line */}
                  <div>
                    <p className="text-gray-600"> ID: {group.id}</p>
                  </div>
                  <div className="border-l h-12 mx-4"></div> {/* Thin vertical line */}
                  <div>
                    <p className="text-gray-600">Admins: {group.admins.length}</p>
                  </div>
                  <div className="border-l h-12 mx-4"></div> {/* Thin vertical line */}
                  <div>
                    <p className="text-gray-600">Members: {group.members.length}</p>
                  </div>
                  <div className="border-l h-12 mx-4"></div> {/* Thin vertical line */}
                </div>
                <div className="flex gap-[10px] mt-2">
                  {/* Buttons for user management */}
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => openAccessLink(group.id)}
                  >
                    View Group
                  </button>
                  
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
