import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('price_1NotTWEmP0Hu3VN1lPtgr8u2');

export default async function getStripeCustomers() {
  const stripe = require('stripe')(
    'sk_test_51Nb7vpEmP0Hu3VN1S70ChB6bFnuUNFKKyE9juGAK6EFORkFnC0o3X45x0thdmHiqtbwsIVHbo99CCdC5vNucvnzY00c7lEjR9b'
  );
  // Retrieve a list of all customers
  const customerList = await stripe.customers.list();
  return customerList.data; // Return the array of customer data
}



