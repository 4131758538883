import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBeE75NRo-EGNP9YIxMhzsEVSIQGv1cy1c",
  authDomain: "admin-prayitforward.firebaseapp.com",
  projectId: "admin-prayitforward",
  storageBucket: "admin-prayitforward.appspot.com",
  messagingSenderId: "1097852331343",
  appId: "1:1097852331343:web:113e42924fa782f604d13a",
  measurementId: "G-KWMFXD1D5T"
};

const dataConfig = {
  apiKey: "AIzaSyBwE9gjKLUcg1aJImXN5H1xeEsJt3-F0oc",
  authDomain: "prayitforward-828ad.firebaseapp.com",
  projectId: "prayitforward-828ad",
  storageBucket: "prayitforward-828ad.appspot.com",
  messagingSenderId: "1033597477577",
  appId: "1:1033597477577:web:4dbd1bab28b9531f7f6318",
  measurementID: 'G-BR7VYS3TKQ'
};


const app = initializeApp(firebaseConfig);
const otherApp = initializeApp(dataConfig, "other");

const projectAuth = getAuth(app);
const firestore = getFirestore(otherApp);

export { projectAuth, firestore };
