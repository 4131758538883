import React from "react";
import { Outlet } from 'react-router-dom';

import { useLocation, Navigate } from "react-router-dom";
import Dashboardview from './components/Dashboardview';
import Sidebar from './components/Sidebar';
import logo from './logo.svg';
import PrivateRoutesLayout from "./layouts/PrivateRoutesLayout";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";


// You can customize the Chakra theme if needed


function App() {
  const location = useLocation();

  return (
    <ChakraProvider>
    <div className="">
      {/* The main layout is divided into two columns */}
      <div className="flex overflow-scroll ">
        {/* Sets the sidebar space to 12 percent of the screen */}
        <div className="basis-[12%] h-[100vh]">
          <Sidebar />
        </div>
        {/* Main Content Area */}
        {/* Sets the space to 12 percent of the screen */}
        <div className="basis-[88%] border overflow-scroll h-[100vh]">
          {/* Renders the Dashboardview file */}
          <Dashboardview />

          {/* Use the PrivateRoutesLayout component for private routes */}
          <PrivateRoutesLayout location={location} />
         
        </div>
      </div>
    </div>
    </ChakraProvider>
  );
}

export default App;