import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Dashboardview from '../components/Dashboardview';
import { fetchUsersFromFirestore, fetchSubscriptionsForUser } from '../operators/manageUserOperations'; // Import Firestore functions

export default function ManageUsers() {
  const [searchQuery, setSearchQuery] = useState('');
  const [showLimit, setShowLimit] = useState(6);
  const [userData, setUserData] = useState([]); // State to hold user data
  const [userStatuses, setUserStatuses] = useState({}); // State to hold user statuses

  useEffect(() => {
    document.title = "Users - PrayitForward";

    // Fetch user data and statuses from Firestore when the component mounts
    const fetchUserData = async () => {
      const users = await fetchUsersFromFirestore();
      setUserData(users);

      // Fetch user statuses
      const statusPromises = users.map(async (user) => {
        const subscriptions = await fetchSubscriptionsForUser(user.id);

        // Check if any subscription has an active status
        const isActive = subscriptions.some(subscription => subscription.status === 'active');

        // Update the userStatuses state
        setUserStatuses(prevStatuses => ({
          ...prevStatuses,
          [user.id]: isActive ? 'Active' : 'Inactive',
        }));
      });

      // Wait for all status promises to resolve
      await Promise.all(statusPromises);
    };

    fetchUserData();
  }, []);

  // Function to filter users based on the search query
  const filterUsers = () => {
    return userData.filter(user => {
      const id = user.id || '';
      const username = user.username || '';
      const email = user.email || '';

      return (
        id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (user.stripeLink && user.stripeLink.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    });
  };

  // Display a limited number of users initially
  const displayedUsers = searchQuery ? filterUsers() : userData.slice(0, showLimit);

  // Function to open a link in a new tab with the user's ID
  const openAccessLink = (userId) => {
    if (userId) {
      const accessLink = `https://prayitforward.com/protected/profile/${userId}`;
      window.open(accessLink, '_blank');
    }
  };

  // Function to open the stripeLink in a new tab
  const openStripeLink = (stripeLink) => {
    if (stripeLink) {
      window.open(stripeLink, '_blank');
    }
  };

  return (
    <div className="">
      {/* The main layout is divided into two columns */}
      <div className="flex overflow-scroll">
        {/* Sidebar */}
        <div className="basis-[12%] h-[100vh]">
          <Sidebar />
        </div>
        {/* Main Content Area */}
        <div className="basis-[88%] border overflow-scroll h-[100vh]">
          <Dashboardview />
          <div className="flex gap-4 mb-4 rounded-md overflow-hidden border border-[#4E73DF]">
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className="w-full h-10 px-4 bg-white focus:outline-none"
            />
          </div>
          {/* Adding user info boxes */}
          <div className="flex flex-col gap-[15px]">
            {displayedUsers.map((user, index) => (
              <div
                key={index}
                className="w-full h-[100px] rounded-[8px] bg-white border-l-[0px] border-[#4E73DF] flex items-center justify-between px-[30px] shadow-lg transform hover:scale-[101%] transition duration-300 ease-out user-info-box"
              >
                <div className="flex items-center">
                  <div className="flex-grow">
                    <p className="font-bold text-lg">{user.username}</p>
                  </div>
                  <div className="border-l h-12 mx-4"></div> {/* Thin vertical line */}
                  <div>
                    <p className="text-gray-600"> ID: {user.id}</p>
                  </div>
                  <div className="border-l h-12 mx-4"></div> {/* Thin vertical line */}
                  <div>
                    <p className="text-gray-600">Email: {user.email}</p>
                  </div>
                  <div className="border-l h-12 mx-4"></div> {/* Thin vertical line */}
                  <div>
                    <p className="font-bold text-gray-600">Status: {userStatuses[user.id] || 'inactive'}</p>
                  </div>
                </div>
                <div className="flex gap-[10px] mt-2">
                  {/* Buttons for user management */}
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => openAccessLink(user.id)}
                  >
                    View Profile
                  </button>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded-md"
                    onClick={() => openStripeLink(user.stripeLink)}
                  >
                    Stripe Management
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
