import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { projectAuth } from "../firebase";

// This code checks if the user is logged in or not

const PrivateRoutesLayout = () => {
    const location = useLocation();

    return projectAuth.currentUser ? (
        <Outlet />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );

}

export default PrivateRoutesLayout;