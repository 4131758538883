// firestoreOperations.js
import { firestore } from "../firebase";
import { getDocs, collection, query, where } from 'firebase/firestore';

// Function to fetch users from Firestore
export const fetchUsersFromFirestore = async () => {
  try {
    const usersCollection = collection(firestore, 'users');
    const querySnapshot = await getDocs(usersCollection);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching users from Firestore:', error);
    return [];
  }
};

export const fetchGroupsFromFirestore = async () => {
  try {
    const groupsCollection = collection(firestore, 'groups');
    const querySnapshot = await getDocs(groupsCollection);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching groups from Firestore:', error);
    return [];
  }
};

// Function to fetch subscriptions for a specific user
export const fetchSubscriptionsForUser = async (userId) => {
  try {
    const subscriptionsCollection = collection(firestore, `users/${userId}/subscriptions`);
    const querySnapshot = await getDocs(subscriptionsCollection);
    return querySnapshot.docs.map(doc => doc.data());
  } catch (error) {
    console.error('Error fetching subscriptions:', error);
    return [];
  }
};






