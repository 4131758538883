import React from 'react';
import { FaTachometerAlt, FaRegSun, FaWrench, FaStickyNote, FaRegChartBar, FaRegCalendarAlt, FaChevronRight, FaChevronLeft, FaBolt, FaMoneyBillWaveAlt, FaQuestionCircle } from "react-icons/fa";
import { BiGroup } from "react-icons/bi";
import { Link } from 'react-router-dom';

/*

This file contains all of the components on the sidebar

TO DO
- Figure out sidebar functions
- Add functionality for each option on the sidebar

*/ 


const Sidebar = () => {
    return (
        <div className='bg-[#4E73DF] px-[25px] h-screen'>
            {/* The title for the sidebar */}
            <div className='px-[15px] py-[30px] flex items-center justify-center border-b-[1px] border-[#EDEDED]/[0.3]'>
                <Link to={"/"} className='text-white text-[20px] leading-[24px] font-extrabold cursor-pointer'>Admin Portal</Link>
            </div>
            {/* Dashboard Button... needs clicking functionality */}
            <div className='flex items-center gap-[15px] py-[20px] border-b-[1px] border-[#EDEDED]/[0.3] cursor-pointer'>
                <FaTachometerAlt color='white' />
                <Link to="/" className='text-[14px] leading-[20px] font-bold text-white cursor-pointer hover:transform hover:scale-[103%] transition duration-300 ease-out'>
                Dashboard
                </Link>
            </div>
            {/* 2nd Section */}
            <div className='pt-[15px] border-b-[1px] border-[#EDEDED]/[0.3]'>
                <p className='text-[10px] font-extrabold leading-[16px] text-white/[0.4]'>Admin Tools</p>
                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    {/* 1st option in 2nd section */}
                    <div className='flex items-center gap-[10px]'>
                    <Link to="/general-settings">
                        <FaRegSun color="white" />
                    </Link>
                        <Link to ="/general-settings" className='text-[14px] leading-[20px] font-normal text-white cursor-pointer hover:transform hover:scale-[103%] transition duration-300 ease-out'>General Settings</Link>
                    </div>
                </div>
                {/* 1st option in 2nd section */}
                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                    <Link to="/manage-users">
                        <FaWrench color='white' /> 
                    </Link>
                        <Link to ="/manage-users" className='text-[14px] leading-[20px] font-normal text-white hover:transform hover:scale-[103%] transition duration-300 ease-out'>Manage Users</Link>
                    </div>
                </div>
                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                    <Link to="/manage-groups">
                        <BiGroup color='white' /> 
                    </Link>
                        <Link to ="/manage-groups" className='text-[14px] leading-[20px] font-normal text-white hover:transform hover:scale-[103%] transition duration-300 ease-out'>Manage Groups</Link>
                    </div>
                </div>
                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                    <Link to="/subscriptions">
                        <FaMoneyBillWaveAlt color='white' /> 
                    </Link>
                        <Link to ="/subscriptions" className='text-[14px] leading-[20px] font-normal text-white hover:transform hover:scale-[103%] transition duration-300 ease-out'>View Subscriptions</Link>
                    </div>
                </div>
            </div>
            {/* 3rd Section */}
            <div className='pt-[15px] border-b-[1px] border-[#EDEDED]/[0.3]'>
                <p className='text-[10px] font-extrabold leading-[16px] text-white/[0.4]'> Content Manager</p>
                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                    <Link to="/daily-prayers">
                        <FaStickyNote color='white' /> 
                    </Link>
                        <Link to ="/daily-prayers" className='text-[14px] leading-[20px] font-normal text-white hover:transform hover:scale-[103%] transition duration-300 ease-out'>Daily Prayers</Link>
                    </div>
                    
                </div>
                <div className='flex items-center justify-between gap-[10px] py-[15px] cursor-pointer'>
                    <div className='flex items-center gap-[10px]'>
                    <Link to="/daily-prayers">
                        <FaQuestionCircle color='white' /> 
                    </Link>
                        <Link to ="/daily-questions" className='text-[14px] leading-[20px] font-normal text-white hover:transform hover:scale-[103%] transition duration-300 ease-out'>Daily Questions</Link>
                    </div>
                    
                </div>
                {/* Another Addon Option */}
                <div className='flex items-center gap-[10px] py-[15px] cursor-pointer'>
                <Link to="/post-analytics">
                    <FaRegChartBar color='white' /> 
                </Link>           
                    <Link to ="/post-analytics" className='text-[14px] leading-[20px] font-normal text-white hover:transform hover:scale-[103%] transition duration-300 ease-out'>Post Analytics</Link>
                </div>
                {/* Yet Another Addon Option */}
                <div className='flex items-center gap-[10px] py-[15px] cursor-pointer'>
                <Link to="/calendar">
                    <FaRegCalendarAlt color='white' /> 
                </Link>
                    <Link to ="/dayinhistory" className='text-[14px] leading-[20px] font-normal text-white hover:transform hover:scale-[103%] transition duration-300 ease-out'>Today in History</Link>
                </div>
            </div>

        </div>
    )
}

export default Sidebar;
