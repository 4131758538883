import React, { useEffect } from "react";
import Login from "../components/Login";
import { useState } from "react";


export default function Authentication() {
    useEffect(() => {
        // Set the title when the component mounts
        document.title = "Admin Login - PrayitForward";
      }, []);
    return (
        <div>
            <Login />
        </div>
    );
}


